import React, { useEffect, useState, useRef, ReactNode, MutableRefObject } from 'react';
import Tabla from './components/Tabla';
import axios from 'axios';

import "./App.css"
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoiYXJlc2xvbHhkIiwiYSI6ImNsY3F1b250ZDA5ajIzcHF2cHY2dGgwNzUifQ.rV_2ZZIrI6Bkc7QrjzHx8A';

function App() {
  const [ubicaciones, setUbicaciones] = useState([]);
  const idInterval = useRef(null);
  const mapContainer = useRef(null);
  const sideBarContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-99.133333);
  const [lat, setLat] = useState(19.432778);
  const [zoom, setZoom] = useState(15);
  const banderaInicio = useRef(true);
  useEffect(() => {
    idInterval.current = window.setInterval(() => {
      axios.get('https://discrelion.payansoftware.org/ubicacion/obtener')
        .then((response) => {
          const ubicaciones = response.data.ubicaciones.reverse();
          setUbicaciones(ubicaciones);

        })
        .catch((error) => {
          console.log(error);
        });
    }, 5000);
    return () => {
      if (idInterval.current) {
        window.clearInterval(idInterval.current);
      }
    }
  }, [])
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v12',
      center: [lng, lat],
      zoom: zoom
    });

  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(6));
      setLat(map.current.getCenter().lat.toFixed(6));
      setZoom(map.current.getZoom().toFixed(2));
    });
    if (banderaInicio.current && ubicaciones.length > 0) {
      const ubicacion = ubicaciones[0];
      let longitude = ubicacion.longitude;
      let latitude = ubicacion.latitude;
      if (longitude === null || latitude === null) {
        longitude = ubicacion.longitudPhone;
        latitude = ubicacion.latitudPhone;
      }
      if (longitude === null || latitude === null) {
        longitude = ubicacion.longitudeIP;
        latitude = ubicacion.latitudeIP;
      }
      if (longitude === null || latitude === null) {
        longitude = ubicacion.longitudGoogle;
        latitude = ubicacion.latitudGoogle;
      }

      map.current.flyTo({
        center: [
          longitude,
          latitude],
        essential: true,
        zoom: 16
      });
      sideBarContainer.current.scrollIntoView({ behavior: 'smooth' });
      setLat(latitude);
      setLng(longitude);
      banderaInicio.current = false;
    }
  });

  return (
    <div className="App">
      <div className="row">

        <div ref={sideBarContainer} className="sidebar">
          Longitud: {lng} | Latitud: {lat} | Zoom: {zoom}
        </div>
        <div ref={mapContainer} className="map-container">
        </div>

      </div>
      <div className="row">

        <Tabla ubicaciones={ubicaciones} onShowMap={(latitude, longitude) => {
          map.current.flyTo({
            center: [longitude, latitude],
            essential: true,
            zoom: 16
          });
          sideBarContainer.current.scrollIntoView({ behavior: 'smooth' });
          setLat(latitude);
          setLng(longitude);
        }} />

      </div>

    </div>
  );
}

export default App;
