import "./tabla.css";

const Tabla = props => {

    const { ubicaciones, onShowMap } = props;

    return (
        <table>
            <thead>
                <tr>
                    <th>ID del registro</th>
                    <th>MAC del dipositivo</th>
                    <th>Longitud</th>
                    <th>Latitud</th>
                    <th>Altitud</th>
                    <th>Longitud del telefono</th>
                    <th>Latitud del telefono</th>
                    <th>Altitud del telefono</th>
                    <th>Longitud de la IP</th>
                    <th>Latitud de la IP</th>
                    <th>Altitud de la IP</th>
                    <th>Longitud del Google</th>
                    <th>Latitud del Google</th>
                    <th>Altitud del Google</th>
                    <th>Bateria del dispositivo</th>
                    <th>Fecha</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {ubicaciones.map((ubicacion, index) => {
                    return (
                        <tr key={index}>
                            <td>{ubicacion._id}</td>
                            <td>{ubicacion.id}</td>
                            <td>{ubicacion.latitude}</td>
                            <td>{ubicacion.longitude}</td>
                            <td>{ubicacion.altitude}</td>
                            <td>{ubicacion.latitudePhone}</td>
                            <td>{ubicacion.longitudePhone}</td>
                            <td>{ubicacion.altitudePhone}</td>
                            <td>{ubicacion.latitudeIP}</td>
                            <td>{ubicacion.longitudeIP}</td>
                            <td>{ubicacion.altitudeIP}</td>
                            <td>{ubicacion.latitudeGoogle}</td>
                            <td>{ubicacion.longitudeGoogle}</td>
                            <td>{ubicacion.altitudeGoogle}</td>
                            <td>{ubicacion.bateria}</td>
                            <td>{(new Date(ubicacion.fecha)).toLocaleString()}</td>
                            <td>

                                <button onClick={() => {
                                    if (onShowMap)
                                        onShowMap(ubicacion.latitude ?? 0,
                                            ubicacion.longitude ?? 0)

                                }}> Mostrar Dispositivo</button>
                                <br />
                                <button onClick={() => {
                                    if (onShowMap)
                                        onShowMap(ubicacion.latitudePhone ?? 0,
                                            ubicacion.longitudePhone ?? 0)
                                }}> Mostrar Telefono</button>

                                <br />
                                <button onClick={() => {
                                    if (onShowMap)
                                        onShowMap(ubicacion.latitudeIP ?? 0,
                                            ubicacion.longitudeIP ?? 0)
                                }}> Mostrar IP</button>
                                <br />
                                <button onClick={() => {
                                    if (onShowMap)
                                        onShowMap(ubicacion.latitudeGoogle ?? 0,
                                            ubicacion.longitudeGoogle ?? 0)
                                }}> Mostrar Google</button>
                            </td>

                        </tr>
                    );
                })}
            </tbody>
        </table>
    );

}

export default Tabla;
